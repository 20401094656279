import { alpha, ThemeOptions } from '@mui/material'

export const secondaryFont = 'new-kansas, sans-serif'

export const partialLightThemeOptions: ThemeOptions = {
  palette: {
    background: {
      paper: '#FAFAFA',
    },
    info: {
      main: '#72b4f4',
    },
    primary: {
      main: '#463dc6',
    },
    secondary: {
      main: '#186ecc',
    },
    success: {
      main: '#48BA4B',
    },
    text: {
      primary: '#060312',
      secondary: alpha('#060312', 0.85),
    },
  },
}
