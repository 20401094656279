import { UserEvents } from './UserEvents.ts'

// we call this outside the hook to force initialization on page load
const instance = UserEvents.get()

const useXyoUserEvents = () => {
  return instance
}

export * from './XyEventHandler.ts'
export { useXyoUserEvents }

export { UserEventId } from './UserEventId.ts'
export { UserEvents } from './UserEvents.ts'
