import { FunnelStartedFields, TestStartedFields, UserClickFields, UserEventHandler, ViewContentFields } from '@xylabs/pixel'

import { CustomProperties } from './CustomProperties.ts'
import { FacebookEvents } from './Facebook/index.ts'
import { XyEventHandlerInterface, XyoNewsletterSignupFields } from './XyEventHandler.ts'

export class FacebookEventHandler<T> extends UserEventHandler<T> implements XyEventHandlerInterface<T> {
  private events = new FacebookEvents<CustomProperties>()

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    await this.events.viewContent().send({ content_category: fields.name })
  }

  async xyoNewsletterSignup(fields: XyoNewsletterSignupFields) {
    await this.events.lead().send({ content_category: fields.name })
  }
}
