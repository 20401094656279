import { FunnelStartedFields, TestStartedFields, UserClickFields, UserEventHandler, ViewContentFields } from '@xylabs/pixel'

import { FacebookEventHandler } from './FacebookEventHandler.ts'
import { GoogleEventHandler } from './GoogleEventHandler.ts'
import { XyEventHandler, XyEventHandlerInterface, XyoNewsletterSignupFields } from './XyEventHandler.ts'

export class UserEvents<T extends Record<string, unknown>> extends UserEventHandler<T> {
  static instance: UserEvents<Record<string, unknown>>

  protected handlers: XyEventHandlerInterface<T>[]

  private constructor(handlers?: XyEventHandlerInterface<T>[]) {
    super()
    this.handlers = handlers ?? [new XyEventHandler<T>(), new FacebookEventHandler<T>(), new GoogleEventHandler<T>()]
  }

  static get() {
    this.instance = this.instance ?? new UserEvents()
    return this.instance
  }

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await Promise.all(this.handlers.map(handler => handler.userClick(fields)))
  }

  async viewContent(fields: ViewContentFields) {
    await Promise.all(this.handlers.map(handler => handler.viewContent(fields)))
  }

  async xyoNewsletterSignup(fields: XyoNewsletterSignupFields) {
    await Promise.all(this.handlers.map(handler => handler.xyoNewsletterSignup(fields)))
  }
}
