import { ListItemIcon, MenuItem, Typography, useTheme } from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React, { useState } from 'react'

import { SlideInArrow } from '../../common/index.ts'
import { NavBarItemProps } from './NavbarDropdownDataProps.ts'

export interface NavBarMenuItemProps {
  index: number
  item: NavBarItemProps
  totalSectionItems: Array<NavBarItemProps>
}

export const NavBarMenuItem: React.FC<NavBarMenuItemProps> = ({ item, index, totalSectionItems }) => {
  const [hovered, setHovered] = useState(false)
  const theme = useTheme()

  return (
    <LinkEx
      color={hovered ? 'primary' : 'inherit'}
      to={item.to ?? ''}
      href={item.href}
      variant="body2"
      gutterBottom
      target={item.href ? '_blank' : '_self'}
    >
      <MenuItem
        key={index}
        divider={index == totalSectionItems.length - 1 ? false : true}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        disabled={item.disabled}
        aria-dsa={!!item.disabled}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          'alignItems': 'flex-start',
          'backgroundColor': 'transparent',
          'paddingY': '8px',
          'whiteSpace': 'normal',
        }}
      >
        <ListItemIcon style={{ color: hovered ? theme.palette.primary.main : theme.palette.text.primary, paddingTop: '3px' }}>
          {item.icon()}
        </ListItemIcon>
        <FlexCol alignItems="flex-start">
          <Typography variant="body1" color={hovered ? theme.palette.primary.main : theme.palette.text.primary}>
            {item.linkText}
            {' '}
            {item.desc ? null : <SlideInArrow inTrigger={hovered} />}
          </Typography>
          <Typography variant="body2" color={hovered ? theme.palette.text.primary : theme.palette.text.secondary}>
            {item.desc}
            {' '}
            {item.desc
              ? <SlideInArrow inTrigger={hovered} />
              : null}
          </Typography>
        </FlexCol>
      </MenuItem>
    </LinkEx>
  )
}
