import { alpha, InputBase, MenuItem, styled } from '@mui/material'

export const StyledSelect = styled(InputBase, { name: 'XyoStyledSelect' })(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    border: 0,
    borderRadius: '60px',
    color: theme.palette.secondary.main,
    padding: theme.spacing(1.5),
  },
})) as typeof InputBase

export const StyledMenuItem = styled(MenuItem, { name: 'XyoStyledSelect' })(({ theme }) => ({
  border: 0,
  color: alpha(theme.palette.text.secondary, 0.6),
})) as typeof MenuItem
