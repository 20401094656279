import { MenuList, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme, Zoom } from '@mui/material'
import { FlexGrowCol, FlexGrowRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React, { useState } from 'react'

import { SlideInArrow } from '../../common/index.ts'
import { NavBarMenuItem } from './NavBarList.tsx'
import { TwoColDropdownProps } from './NavDropdownProps.tsx'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[4],
    color: 'inherit',
    maxWidth: '600px',
    overflow: 'hidden',
    padding: 0,
  },
}))

export const TwoColDropdown: React.FC<TwoColDropdownProps> = ({
  name,
  to,
  leftColItems,
  cta,
  placement = 'bottom-center' as TooltipProps['placement'],
}) => {
  const theme = useTheme()
  const [leftHovered, setLeftHovered] = useState(false)
  const [toolTipHovered, setToolTipHovered] = useState(false)
  return (
    <HtmlTooltip
      id={name + 'menu'}
      placement={placement}
      arrow
      onMouseEnter={() => setToolTipHovered(true)}
      onMouseLeave={() => setToolTipHovered(false)}
      title={(
        <>
          <FlexGrowRow display="flex">
            {cta
              ? (
                  <LinkEx to={cta.ctaLink} target="_blank" alignSelf="flex-end">
                    <FlexGrowCol
                      alignItems="flex-start"
                      padding={1}
                      display="flex"
                      width="200px"
                      onMouseEnter={() => setLeftHovered(true)}
                      onMouseLeave={() => setLeftHovered(false)}
                    >
                      <Zoom in={leftHovered} timeout={700}>
                        <Typography variant="h6">{cta?.ctaIcon}</Typography>
                      </Zoom>
                      <Typography variant="body2" color={theme.palette.primary.contrastText}>
                        {cta?.ctaTitle}
                      </Typography>
                      <Typography variant="caption" color={theme.palette.primary.contrastText} display="flex" alignItems="center">
                        <span>
                          {cta?.ctaText}
                          <SlideInArrow inTrigger={leftHovered} />
                        </span>
                      </Typography>
                    </FlexGrowCol>
                  </LinkEx>
                )
              : null}
            <FlexGrowCol bgcolor={theme.palette.background.default}>
              <MenuList>
                {leftColItems?.map((child, index) => {
                  return <NavBarMenuItem key={index} index={index} item={child} totalSectionItems={leftColItems} />
                })}
              </MenuList>
            </FlexGrowCol>
          </FlexGrowRow>
        </>
      )}
    >
      <LinkEx padding={1} to={to} aria-controls={name + 'menu'} id={name + 'button'} aria-haspopup="true">
        <Typography variant="body1" style={{ color: toolTipHovered ? theme.palette.primary.main : theme.palette.text.primary }}>
          {name}
        </Typography>
      </LinkEx>
    </HtmlTooltip>
  )
}
