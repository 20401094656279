import { FunnelStartedFields, TestStartedFields, UserClickFields, UserEventHandler, ViewContentFields } from '@xylabs/pixel'
import { GoogleCartProperties } from '@xylabs/react-pixel'

import { CustomProperties } from './CustomProperties.ts'
import { GoogleEvents } from './Google/index.ts'
import { XyEventHandlerInterface, XyoNewsletterSignupFields } from './XyEventHandler.ts'

export class GoogleEventHandler<T extends Record<string, unknown>> extends UserEventHandler<T> implements XyEventHandlerInterface<T> {
  private events = new GoogleEvents<CustomProperties | GoogleCartProperties>()

  funnelStarted(_fields: T | FunnelStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  testStarted(_fields: T | TestStartedFields): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async userClick(fields: UserClickFields) {
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    await this.events.viewContent().send(fields)
  }

  async xyoNewsletterSignup(fields: XyoNewsletterSignupFields) {
    await this.events.viewContent().send(fields)
  }
}
