import { Typography } from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React, { ReactElement } from 'react'

export interface SubLinkSectionProps {
  subLinkIcon?: ReactElement
  subLinkPath?: string
  subLinkText1?: string
  subLinkText2?: string
}

export const SubLinkSection: React.FC<SubLinkSectionProps> = ({ subLinkIcon, subLinkPath, subLinkText1, subLinkText2 }) => {
  return (
    <FlexGrowRow width="100%" sx={{ flexDirection: { md: 'row', xs: 'column' }, justifyContent: { md: 'flex-start', xs: 'center' } }}>
      {subLinkIcon
        ? (
            <span>
              {subLinkIcon}
&nbsp;
            </span>
          )
        : null}
      <Typography>
        {subLinkText1}
&nbsp;
      </Typography>
      <LinkEx href={subLinkPath} underline="always" target="_blank" color="inherit">
        <Typography>{subLinkText2}</Typography>
      </LinkEx>
    </FlexGrowRow>
  )
}
