import { alpha, styled, ToggleButton, ToggleButtonGroup } from '@mui/material'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup, { name: 'XyoStyledToggleButtonGroup' })(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.05),
      },
      'backgroundColor': alpha(theme.palette.secondary.main, 0.05),
      'borderRadius': '70px',
      'color': theme.palette.secondary.main,
    },
    'border': 0,
    'color': alpha(theme.palette.text.secondary, 0.6),
    'margin': theme.spacing(0.5),
  },
})) as typeof ToggleButtonGroup

export const StyledToggleButton = styled(ToggleButton, { name: 'XyoStyledToggleButton' })(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },
  'color': theme.palette.text.secondary,
})) as typeof ToggleButtonGroup
