import { ThemeOptions } from '@mui/material'

export const secondaryFont = 'new-kansas, sans-serif'

export const partialDarkThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: '#0D0E15',
      paper: '#080D13',
    },
    info: {
      main: '#72b4f4',
    },
    mode: 'dark',
    primary: {
      main: '#B0B3F7',
    },
    secondary: {
      main: '#49B3E3',
    },
    success: {
      main: '#48BA4B',
    },
    text: {
      disabled: '#a5acdb',
      primary: '#E3E4EB',
      secondary: '#B3B3BD',
    },
  },
  typography: {
    body1: {
      color: '#B3B3BD',
    },
    body2: {
      color: '#B3B3BD',
    },
    caption: {
      color: '#B3B3BD',
    },
    h6: {
      color: '##DCDCE5',
    },
  },
}
