import { ArrowForwardRounded } from '@mui/icons-material'
import { Fade, Slide, SlideProps } from '@mui/material'
import React from 'react'

export interface SlideInArrowProps {
  inTrigger: SlideProps['in']
}

export const SlideInArrow: React.FC<SlideInArrowProps> = ({ inTrigger = false }) => {
  const containerRef = React.useRef<HTMLElement>(null)

  return (
    <span ref={containerRef}>
      <Slide direction="right" in={inTrigger} container={containerRef.current}>
        <Fade in={inTrigger}>
          <ArrowForwardRounded sx={{ fontSize: 'inherit', verticalAlign: 'middle' }} />
        </Fade>
      </Slide>
    </span>
  )
}
