import { alpha, Container, Typography, useTheme } from '@mui/material'
import { BusyBoxProps, FlexGrowCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { secondaryFont } from '../partialDarkThemeOptions.tsx'
import { EmailNewsletterTextField } from './EmailNewsletterTextField.tsx'

export const Newsletter: React.FC<BusyBoxProps> = () => {
  const theme = useTheme()
  return (
    <SectionSpacingRow marginTop={10} bgcolor={alpha(theme.palette.primary.main, 0.05)}>
      <Container>
        <FlexGrowCol fontFamily={secondaryFont}>
          <Typography textAlign="center" gutterBottom variant="body1">
            Stay up to date
          </Typography>
          <Typography paddingBottom={3} fontFamily={secondaryFont} gutterBottom textAlign="center" variant="h3">
            Get our newsletter
          </Typography>
          <EmailNewsletterTextField />
        </FlexGrowCol>
      </Container>
    </SectionSpacingRow>
  )
}
