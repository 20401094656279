import { FacebookCustomEvent, FacebookStandardEvents } from '@xylabs/react-pixel'

export interface FacebookUserClick extends Record<string, unknown> {
  elementName: string
  elementType: string
}

export class FacebookEvents<T extends Record<string, unknown>> extends FacebookStandardEvents<T> {
  userClick() {
    return new FacebookCustomEvent<FacebookUserClick | T>('UserClick')
  }
}
