import { Stack, Toolbar, ToolbarProps, TooltipProps, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

import { RotationAnimation } from '../common/index.ts'
import { useSettings } from '../contexts/index.ts'
import { XYOIconBlack, XYOIconColor, XYOTextBlack, XYOTextColor } from '../img/index.ts'
import { NavbarData, TwoColDropdown } from './Dropdown/index.ts'

export const ContextToolbar: React.FC<ToolbarProps> = (props) => {
  const theme = useTheme()
  const { darkMode } = useSettings()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <Toolbar {...props} disableGutters>
      <FlexGrowRow>
        <LinkEx to="/">
          <Stack direction="row" paddingRight={1} spacing={0.5} alignItems="center" justifyContent="center">
            <RotationAnimation rotation={20}>
              <img src={darkMode ? XYOIconColor : XYOIconBlack} height={30} alt="XYO Icon Logo" />
            </RotationAnimation>
            <img src={darkMode ? XYOTextColor : XYOTextBlack} height={22} alt="XYO Text Logo" />
          </Stack>
        </LinkEx>
        <FlexGrowRow>
          {!isNavbarMobile
          && NavbarData.map((section, index: number) => {
            return (
              <TwoColDropdown
                placement={
                  index === 0
                    ? ('bottom-start' as TooltipProps['placement'])
                    : index === NavbarData.length - 1
                      ? ('bottom-end' as TooltipProps['placement'])
                      : ('bottom' as TooltipProps['placement'])
                }
                name={section.name}
                to={section.to}
                key={index}
                leftColItems={section.leftColItems}
                cta={section.cta}
              />
            )
          })}
        </FlexGrowRow>
      </FlexGrowRow>
    </Toolbar>
  )
}
