import { LaunchRounded } from '@mui/icons-material'
import { Container, Grid, Stack, Typography, useTheme } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexGrowCol } from '@xylabs/react-flexbox'
import { useGradientStyles } from '@xyo-network/react-shared'
import React, { ReactElement, ReactNode } from 'react'

import { secondaryFont } from '../../partialDarkThemeOptions.tsx'
import { HomePageBackgroundBox } from '../GradientBackgrounds.tsx'
import { ButtonSection } from './ButtonSection.tsx'
import { SubLinkSection } from './SubLinkSection.tsx'

export interface BasicHeroProps extends FlexBoxProps {
  backgroundImage?: string
  button1Href?: string
  button1Text?: string
  button1To?: string
  button2Href?: string
  button2Text?: string
  button2To?: string
  desc: string
  extras?: ReactNode
  gradientTitle?: string
  heroComponent?: ReactNode
  heroImage?: string
  subLinkIcon?: ReactElement
  subLinkPath?: string
  subLinkText1?: string
  subLinkText2?: string
  sx?: Record<string, string>
  title: string
  title2?: string
}

export const XyoBasicHero: React.FC<BasicHeroProps> = ({
  backgroundImage,
  title,
  gradientTitle,
  desc,
  heroComponent,
  heroImage,
  title2,
  subLinkText1,
  subLinkText2,
  subLinkPath,
  button1Text,
  button2Text,
  button2To,
  button1To,
  button2Href,
  button1Href,
  subLinkIcon,
  extras,
  sx,
  ...props
}) => {
  const { classes } = useGradientStyles()
  const theme = useTheme()

  return (
    <HomePageBackgroundBox>
      <FlexGrowCol
        sx={{
          backgroundImage: { md: `url(${backgroundImage})`, xs: 'none' },
          backgroundPosition: { lg: 'center left', md: 'center left', xs: 'center left' },
          backgroundSize: { md: 'cover', xs: '100%' },
          justifyContent: 'flex-start',
          minHeight: {
            md: '500px',
            sm: '500px',
            xs: '400px',
          },
          overflow: 'hidden',
          paddingTop: { md: theme.spacing(0), xs: theme.spacing(2) },
          ...sx,
        }}
        style={{
          backgroundRepeat: 'no-repeat',
        }}
        {...props}
      >
        <Container maxWidth="lg">
          <Grid
            container
            sx={{
              alignItems: { xs: 'center' },
              justifyContent: { md: 'flex-start', xs: 'center' },
            }}
          >
            <Grid item xs={12} sm={10} md={backgroundImage || heroImage ? 6 : 8} lg={backgroundImage || heroImage ? 6 : 8}>
              <FlexGrowCol paddingY={3} sx={{ alignItems: { md: 'flex-start', xs: 'center' }, marginTop: { lg: 5, md: 3, xs: 2 } }}>
                <Typography variant="h2" component="h1" gutterBottom fontFamily={secondaryFont} sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                  {title
                    ? <span>{`${title} `}</span>
                    : null}
                  {gradientTitle
                    ? (
                        <span className={classes().heading}>
                          {' '}
                          {` ${gradientTitle}`}
                        </span>
                      )
                    : null}
                  {title2
                    ? <span>{` ${title2}`}</span>
                    : null}
                </Typography>
                <Typography variant="body1" component="h2" gutterBottom sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                  {desc}
                </Typography>
                <Stack sx={{ flexDirection: { md: 'row', xs: 'column' } }} gap={1} marginTop={1}>
                  <ButtonSection
                    endIcon={button1Href?.length !== undefined && button1Href?.length > 0 ? <LaunchRounded /> : undefined}
                    href={button1Href}
                    to={button1To}
                    buttonText={button1Text}
                    onClick={() => {
                      if (button1To?.startsWith('#')) {
                        const section = document.querySelector(button1To)
                        section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }
                    }}
                  />
                  <ButtonSection
                    endIcon={button2Href?.length !== undefined && button2Href?.length > 0 ? <LaunchRounded /> : undefined}
                    variant="outlined"
                    href={button2Href}
                    to={button2To}
                    buttonText={button2Text}
                    onClick={() => {
                      if (button2To?.startsWith('#')) {
                        const section = document.querySelector(button2To)
                        section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }
                    }}
                  />
                </Stack>
                {subLinkPath
                  ? <SubLinkSection subLinkIcon={subLinkIcon} subLinkText1={subLinkText1} subLinkText2={subLinkText2} subLinkPath={subLinkPath} />
                  : null}
                {extras}
              </FlexGrowCol>
            </Grid>
            <Grid item xs={12} md={6} alignItems="center">
              <FlexCol alignItems="center">
                {heroComponent}
                {heroImage
                  ? <img src={heroImage} style={{ maxHeight: '500px' }} width="auto" />
                  : null}
              </FlexCol>
            </Grid>
          </Grid>
        </Container>
      </FlexGrowCol>
    </HomePageBackgroundBox>
  )
}
