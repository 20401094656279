import { alpha, Box, styled } from '@mui/material'

export const HomePageBackgroundBox = styled(Box, { name: 'HomePageBackgroundBox' })(({ theme }) => ({
  background: `radial-gradient(circle 300px at 10% 10%, ${alpha('#3420B0', 0.15)}, ${alpha(theme.palette.background.default, 0.1)}),
          radial-gradient(circle 300px at 0% 80%, ${alpha('#FF5BDC', 0.15)}, ${alpha(theme.palette.background.default, 0.1)}),
          radial-gradient(circle 300px at 45% 120%, ${alpha('#3420B0', 0.3)}, ${alpha(
            theme.palette.background.default,
            0.2,
          )}),radial-gradient(circle 300px at 55% -20%, ${alpha('#FF5BDC', 0.15)}, ${alpha(
            theme.palette.background.default,
            0.1,
          )}),radial-gradient(circle 300px at 70% 90%, ${alpha('#3420B0', 0.25)}, ${alpha(
            theme.palette.background.default,
            0.2,
          )}), radial-gradient(circle 300px at 90% 10%, ${alpha('#FF5BDC', 0.25)}, ${alpha(theme.palette.background.default, 0.1)})`,
})) as typeof Box

export const BackgroundGradientBox1 = styled(Box, { name: 'BackgroundGradientBox1' })(({ theme }) => ({
  background: `radial-gradient(circle 400px at 5% 10%, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.15)}, ${alpha(
    theme.palette.background.default,
    0.1,
  )}),
          radial-gradient(circle 300px at 0% 80%, ${alpha('#FF5BDC', 0.15)}, ${alpha(theme.palette.background.default, 0.1)}),
          radial-gradient(circle 600px at 45% 140%, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.3)}, ${alpha(
            theme.palette.background.default,
            0.2,
          )}),radial-gradient(circle 300px at 55% -20%, ${alpha('#FF5BDC', 0.15)}, ${alpha(
            theme.palette.background.default,
            0.1,
          )}),radial-gradient(circle 600px at 50% 50%, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.25)}, ${alpha(
            theme.palette.background.default,
            0.2,
          )}),
          radial-gradient(circle 500px at 68% 40%, ${alpha('#FF5BDC', 0.15)}, ${alpha(
            theme.palette.background.default,
            0.2,
          )}),radial-gradient(circle 500px at 90% 90%, ${alpha(theme.palette.mode === 'dark' ? '#5D3FF2' : '#3420B0', 0.25)}, ${alpha(
            theme.palette.background.default,
            0.2,
          )}), radial-gradient(circle 500px at 95% 10%, ${alpha('#FF5BDC', 0.25)}, ${alpha(theme.palette.background.default, 0.1)})`,
})) as typeof Box
