import { UserClickFields, ViewContentFields } from '@xylabs/pixel'
import { XyCustomEvent } from '@xylabs/react-pixel'

export class XyEvents<T extends Record<string, unknown>> {
  userClick() {
    return new XyCustomEvent<UserClickFields | T>('UserClick')
  }

  viewContent() {
    return new XyCustomEvent<ViewContentFields | T>('ViewContent')
  }
}
