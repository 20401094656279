import { Menu as MenuIcon } from '@mui/icons-material'
import { AccordionSummary, IconButton, SwipeableDrawer, Typography } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol, FlexGrowCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React, { useState } from 'react'

import { MobileAccordion } from './MobileAccordion.tsx'
import { NavbarData } from './NavbarData.tsx'

export const MobileMenuItems: React.FC = () => {
  const [accordionOpenItem, setAccordionOpenItem] = useState(-1)
  return (
    <FlexGrowCol justifyContent="space-between" padding={1}>
      <FlexCol width="215px" gap={1} height="100%" justifyContent="space-between" alignItems="stretch">
        <FlexCol>
          {NavbarData?.map((section, index) => {
            return (
              <FlexCol key={index} justifyContent="flex-start" alignItems="stretch" width="250px" height="100%" marginBottom={1}>
                {section?.leftColItems
                  ? (
                      <MobileAccordion
                        name={section.name}
                        to={section.to}
                        sectionItems={section.leftColItems}
                        index={index}
                        expanded={accordionOpenItem === index}
                        onChange={(event, expanded) => setAccordionOpenItem(expanded ? index : -1)}
                      />
                    )
                  : (
                      <AccordionSummary>
                        <LinkEx style={{ justifyContent: 'start', textAlign: 'start' }} key={index} color="inherit" to={section.to}>
                          <Typography variant="body1" textAlign="left">
                            {section.name}
                          </Typography>
                        </LinkEx>
                      </AccordionSummary>
                    )}
              </FlexCol>
            )
          })}
        </FlexCol>
        <ButtonEx to={{ pathname: '/get-started' }} variant="contained" paddingY={1} size="large" fullWidth marginTop={3}>
          Get Started
        </ButtonEx>
      </FlexCol>
    </FlexGrowCol>
  )
}

export const MobileMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }
    setMenuOpen(open)
  }

  return (
    <>
      <IconButton aria-label="menu-button" onClick={toggleDrawer(!menuOpen)}>
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer elevation={1} anchor="right" open={menuOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} variant="temporary">
        <MobileMenuItems />
      </SwipeableDrawer>
    </>
  )
}
