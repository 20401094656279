/* eslint-disable import-x/no-internal-modules */
import { alpha, CssBaseline, ThemeOptions, useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { Fbq, Gtag, UserEventsProvider } from '@xylabs/react-pixel'
import { ErrorAlert, ThrownErrorBoundary, webThemeOptions } from '@xyo-network/react-sdk'
import deepmerge from 'deepmerge'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import Rollbar from 'rollbar'

import { XyoBasePage } from './common/index.ts'
import { PixelProvider, SettingsLoader, useSettings } from './contexts/index.ts'
import { useXyoUserEvents } from './hooks/index.ts'
import { RootRoutes } from './modules/site/Routes.tsx'
import { partialDarkThemeOptions } from './partialDarkThemeOptions.tsx'
import { partialLightThemeOptions } from './partialLightThemeOptions.tsx'

export const rollbar
  = import.meta.env.ROLLBAR_CLIENT_ACCESS_TOKEN
    ? new Rollbar({
      accessToken: import.meta.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT ?? 'development',
    })
    : undefined

Fbq.init('1653480428329003')
Gtag.init('G-ZSGG0QNJW7', 'AW-989965544', ['xyo.network'])

const AppBodyWithMemoryArchivist: React.FC = () => {
  const theme = useTheme()

  return (
    <BrowserRouter>
      <ThrownErrorBoundary
        boundaryName="Application"
        errorComponent={(error, boundaryName) => (
          <XyoBasePage>
            <ErrorAlert errorContext={boundaryName} error={error} />
          </XyoBasePage>
        )}
        rollbar={rollbar}
      >
        <FlexGrowCol
          width="100vw"
          minHeight="100vh"
          justifyContent="flex-start"
          alignContent="stretch"
          bgcolor={theme.palette.background.default}
          color={theme.palette.text.primary}
        >
          <RootRoutes />
        </FlexGrowCol>
      </ThrownErrorBoundary>
    </BrowserRouter>
  )
}

const AppBody: React.FC = () => {
  const userEvents = useXyoUserEvents()
  /* const [archivist, setArchivist] = useState<MemoryArchivist>()

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      const newArchivist = await MemoryArchivist.create({ config: { name: 'repositoryArchivist', schema: MemoryArchivist.defaultConfigSchema } })
      await initializeMemoryArchivist(newArchivist)
      if (mounted()) {
        setArchivist(newArchivist)
      }
    },
    [],
  ) */

  return (
    <PixelProvider id="xyo.network">
      <ParallaxProvider>
        <UserEventsProvider userEvents={userEvents}>
          {/* {archivist ?
            <MemoryNodeProvider config={{ schema: NodeConfigSchema }} modules={[archivist]}>
              <AppBodyWithMemoryArchivist />
            </MemoryNodeProvider>
          : null} */}
          <AppBodyWithMemoryArchivist />
        </UserEventsProvider>
      </ParallaxProvider>
    </PixelProvider>
  )
}

// Augment the palette to include a neutral color
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

// Update the Button's color options to include a neutral option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

// Update the Button's color options to include a neutral option
declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    neutral: true
  }
}

const AppThemeBody: React.FC = () => {
  const { darkMode } = useSettings()
  const theme = useTheme()
  const overrideOptions: ThemeOptions = {
    breakpoints: {
      values: {
        lg: 1350,
        md: 900,
        sm: 600,
        xl: 1536,
        xs: 0,
      },
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
            color: 'inherit',
          },
          standardError: { backgroundColor: alpha(theme.palette.error.main, 0.3), color: theme.palette.error.light },
          standardInfo: { backgroundColor: alpha(theme.palette.info.main, 0.3), color: theme.palette.info.light },
          standardSuccess: { backgroundColor: alpha(theme.palette.success.main, 0.3), color: theme.palette.success.light },
          standardWarning: { backgroundColor: alpha(theme.palette.warning.main, 0.3), color: theme.palette.warning.light },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorError: { backgroundColor: alpha(theme.palette.error.main, 0.3) },
          colorInfo: { backgroundColor: alpha(theme.palette.info.main, 0.3) },
          colorPrimary: { backgroundColor: alpha(theme.palette.primary.main, 0.3) },
          colorSecondary: { backgroundColor: alpha(theme.palette.secondary.main, 0.3) },
          colorSuccess: { backgroundColor: alpha(theme.palette.success.main, 0.3) },
          colorWarning: { backgroundColor: alpha(theme.palette.warning.main, 0.3) },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: 'xl',
          },
        },
      },
    },
    shape: {
      borderRadius: 4,
    },
    spacing: 12,
    typography: {
      fontFamily: '"Lexend Deca", sans-serif',
      body1: {
        fontSize: '1rem',
        fontWeight: 300,
      },
      button: {
        fontSize: '1rem',
        fontWeight: 300,
        textTransform: 'capitalize',
      },
      fontWeightBold: 700,
      fontWeightLight: 300,
      fontWeightMedium: 400,
      fontWeightRegular: 400,
      h1: {
        fontSize: '3.2rem',
      },
      h2: {
        fontSize: '2.7rem',
      },
      h3: {
        fontSize: '2.24rem',
      },
      h4: {
        fontSize: '2rem',
      },
      h5: {
        fontSize: '1.5rem',
      },
      h6: {
        fontSize: '1.25rem',
      },
      subtitle1: {
        opacity: '50%',
        textTransform: 'uppercase',
      },
      subtitle2: {
        opacity: '50%',
      },
    },
  }
  return (
    <InvertibleThemeProvider
      dark={darkMode}
      darkOptions={partialDarkThemeOptions}
      lightOptions={partialLightThemeOptions}
      options={deepmerge(webThemeOptions, overrideOptions)}
    >
      <AppBody />
    </InvertibleThemeProvider>
  )
}

export const App: React.FC = () => {
  return (
    <SettingsLoader>
      <Helmet defaultTitle="XYO" titleTemplate="%s" />
      <CssBaseline />
      <AppThemeBody />
    </SettingsLoader>
  )
}
