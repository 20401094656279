import { QuestionAnswerRounded } from '@mui/icons-material'
import { Divider } from '@mui/material'
import React from 'react'

import { XyoBasicHero } from '../BasicHero/index.ts'
import { XyoBasePage } from '../XyoBasePage.tsx'

export const Four04Page: React.FC = () => {
  return (
    <XyoBasePage title="404">
      <XyoBasicHero
        alignContent="center"
        title="404"
        desc="Whoops! It looks like that page is no longer available."
        button1Text="Take me home!"
        button1To="/"
        subLinkIcon={<QuestionAnswerRounded />}
        subLinkPath="https://support.xy.company/hc/en-us/requests/new"
        subLinkText1="Think this is a bug?"
        subLinkText2="Report it to our team here."
      />
      <Divider />
    </XyoBasePage>
  )
}
