import { Theme, Toolbar, ToolbarProps, useMediaQuery } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexRow } from '@xylabs/react-flexbox'
import React, { ReactNode } from 'react'
import { To } from 'react-router-dom'

interface XYOToolbarProps extends ToolbarProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const SystemToolBar: React.FC<XYOToolbarProps> = ({ ctaLink, ctaText, customButton, children, ...props }) => {
  const isMedium = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'))
  const ctaLinkTypeTo: boolean = ctaLink?.toString().includes('https') ? false : true
  return (
    <Toolbar disableGutters {...props}>
      <FlexRow gap={1}>
        {customButton ?? (
          <ButtonEx
            flexWrap="nowrap"
            color="primary"
            variant="contained"
            size={isMedium ? 'medium' : 'large'}
            target={ctaLinkTypeTo ? undefined : '_blank'}
            href={ctaLinkTypeTo ? '/ecosystem/earn-as-a-node' : ctaLink?.toString()}
            to={ctaLinkTypeTo ? ctaLink : '/ecosystem/earn-as-a-node'}
            onClick={() => {
              window.mixpanel.track('User Click', {
                Name: 'Earn As A Node',
                Placement: 'navbar-cta',
              })
            }}
          >
            {ctaText ?? 'Earn as a Node'}
          </ButtonEx>
        )}
        {children}
      </FlexRow>
    </Toolbar>
  )
}
