import {
  AlternateEmailRounded,
  AutoAwesomeRounded,
  BuildCircleRounded,
  BusinessCenterRounded,
  CodeRounded,
  ColorLensRounded,
  DeviceHubRounded,
  GroupsRounded,
  HistoryEduRounded,
  HubRounded,
  IntegrationInstructionsRounded,
  LaunchRounded,
  NewspaperRounded,
  PeopleRounded,
  PublicRounded,
  RestoreRounded,
  TollRounded,
  TravelExploreRounded,
} from '@mui/icons-material'
import React from 'react'
// eslint-disable-next-line import-x/no-internal-modules
import { FaXTwitter } from 'react-icons/fa6'

import { RoadmapShadow } from './img/index.ts'
import { TwoColDropdownProps } from './NavDropdownProps.tsx'

export const NavbarData: Array<TwoColDropdownProps> = [
  {
    cta: {
      ctaIcon: <img src={RoadmapShadow} style={{ marginLeft: '-30px', opacity: 0.2, overflow: 'hidden', width: '110%' }} />,
      ctaLink: '/roadmap/current',
      ctaText: 'Read the 2024 Roadmap to learn more about how to earn rewards for completing XYO Bounties.',
      ctaTitle: 'Coming Soon: Build the Future Initiative',
    },
    leftColItems: [
      {
        desc: 'Explore the network and understand the technology',
        icon: props => <CodeRounded {...props} />,
        linkText: 'Developer Overview',
        to: '/developer',
      },
      {
        desc: 'Ready to develop? Come check out our developer docs. More updates, glossary terms, and sample code coming soon.',
        href: 'https://docs.xyo.network/docs',
        icon: props => <IntegrationInstructionsRounded {...props} />,
        linkText: 'Documentation (In Progress)',
      },
      {
        desc: 'View our open-source code on Github',
        href: 'https://github.com/XYOracleNetwork',
        icon: props => <DeviceHubRounded {...props} />,
        linkText: 'XYO Github',
      },
      {
        desc: 'A developer tool to create nodes',
        href: 'https://node.xyo.network',
        icon: props => <HubRounded {...props} />,
        linkText: 'node.xyo.network',
      },
      {
        desc: 'A developer tool to view nodes and explore data',
        href: 'https://explore.xyo.network',
        icon: props => <TravelExploreRounded {...props} />,
        linkText: 'explore.xyo.network',
      },
    ],
    name: 'Developers',
    to: '/developer',
  },
  {
    leftColItems: [
      {
        desc: 'Explore and understand XYO 2.0',
        icon: props => <PublicRounded {...props} />,
        linkText: 'Technology Overview',
        to: '/ecosystem',
      },
      {
        desc: 'Use an XYO-enabled dApp to participate in XYO',
        icon: props => <BuildCircleRounded {...props} />,
        linkText: 'Build The Future Initiative',
        to: '/ecosystem/build-the-future',
      },
      {
        desc: 'Learn about the ERC-20 XYO Token',
        icon: props => <TollRounded {...props} />,
        linkText: 'XYO Token',
        to: '/token',
      },
      {
        desc: 'Use an XYO-enabled dApp to participate in XYO',
        href: 'https://coinapp.co',
        icon: props => <LaunchRounded {...props} />,
        linkText: 'coinapp.co',
      },
    ],

    name: 'Ecosystem',
    to: '/network',
  },
  {
    name: 'Token',
    to: '/token',
  },
  {
    name: 'dApps',
    to: '/dapp',
  },
  {
    cta: {
      ctaIcon: <FaXTwitter />,
      ctaLink: 'https://twitter.com/OfficialXYO',
      ctaText: "Stay up-to-date with XYO's latest announcements on X",
      ctaTitle: 'Follow XYO on X',
    },
    leftColItems: [
      {
        desc: 'Read and listen to articles, interviews, and partnership updates.',
        icon: props => <NewspaperRounded {...props} />,
        linkText: 'News',
        to: '/news',
      },
      {
        desc: 'Get project updates, educational content, and more, delivered straight to your email.',
        icon: props => <AlternateEmailRounded {...props} />,
        linkText: 'Email Newsletter',
        to: '/newsletter',
      },
      {
        desc: 'Meet the XYO Global community, join giveaways, and chat all things XYO.',
        icon: props => <PeopleRounded {...props} />,
        linkText: 'Social Media',
        to: '/community',
      },
    ],
    name: 'Community & News',
    to: '/community',
  },
  {
    cta: {
      ctaIcon: (
        <img src={RoadmapShadow} style={{ fill: 'red', marginLeft: '-30px', opacity: 0.2, overflow: 'hidden', stroke: 'green', width: '110%' }} />
      ),
      ctaLink: 'https://medium.com/xyonetwork/7083c6ec7c7f',
      ctaText: 'Read the announcement on Medium',
      ctaTitle: '2024 Roadmap Article',
    },
    leftColItems: [
      {
        desc: 'Join us on our next phase of XYO: The Build the Future Initiative and an easy, every-day blockchain option for users and developers everywhere.',
        icon: props => <AutoAwesomeRounded {...props} />,
        linkText: '2024 Roadmap',
        to: '/roadmap',
      },
      {
        desc: 'Look back our 2023 goals, new product roll outs, achievements, and more.',
        icon: props => <RestoreRounded {...props} />,
        linkText: 'Past Roadmap (2023)',
        to: '/roadmap/2023',
      },
    ],
    name: 'Roadmap',
    to: '/roadmap',
  },
  {
    leftColItems: [
      {
        desc: 'Read the foundational XYO white paper, or learn about token economics, security risk mitigation, and more. ',
        icon: props => <HistoryEduRounded {...props} />,
        linkText: 'Papers',
        to: '/papers',
      },
      {
        desc: 'Combining efforts to bring the world new technologies.',
        icon: props => <BusinessCenterRounded {...props} />,
        linkText: 'Partners',
        to: '/partners',
      },
      {
        desc: 'View colors, logos, and other assets.',
        icon: props => <ColorLensRounded {...props} />,
        linkText: 'Brand',
        to: '/brand',
      },
      {
        desc: 'Learn more about the company behind XYO',
        href: 'https://xylabs.com/company/team',
        icon: props => <GroupsRounded {...props} />,
        linkText: 'XY Labs & The Team',
      },
    ],
    name: 'Resources',
    to: '/resources',
  },
]
