import { GoogleCustomEvent, GoogleStandardEvents } from '@xylabs/react-pixel'

export interface GoogleViewContent extends Record<string, unknown> {
  name: string
  path: string
}

export interface GoogleUserClick extends Record<string, unknown> {
  elementName: string
  elementType: string
}

export class GoogleEvents<T extends Record<string, unknown>> extends GoogleStandardEvents<T> {
  userClick() {
    return new GoogleCustomEvent<GoogleUserClick | T>('UserClick')
  }

  viewContent() {
    return new GoogleCustomEvent<GoogleViewContent | T>('ViewContent')
  }
}
