import { alpha, useMediaQuery, useTheme } from '@mui/material'
import { AppBarEx, AppBarExProps } from '@xylabs/react-appbar'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import React, { ReactNode } from 'react'
import { To } from 'react-router-dom'

import { ContextToolbar } from './ContextToolbar.tsx'
import { MobileMenu } from './Dropdown/index.ts'
import { SystemToolBar } from './SystemToolbar/index.ts'

interface XyoAppBarExProps extends AppBarExProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const XyoAppBar: React.FC<XyoAppBarExProps> = ({ ctaLink, ctaText, customButton, style, contextToolbar, systemToolbar, ...props }) => {
  const theme = useTheme()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <AppBarEx
      container="lg"
      elevation={0}
      style={{
        WebkitBackdropFilter: 'saturate(180%) blur(20px)',
        backdropFilter: 'saturate(180%) blur(20px)',
        backgroundColor: alpha(theme.palette.background.paper, 0.3),
        color: theme.palette.getContrastText(theme.palette.background.paper),
        ...style,
      }}
      contextToolbar={contextToolbar ?? <ContextToolbar />}
      systemToolbar={
        systemToolbar ?? (
          <SystemToolBar ctaLink={ctaLink} ctaText={ctaText} customButton={customButton}>
            {isNavbarMobile
              ? <MobileMenu />
              : null}
          </SystemToolBar>
        )
      }
      onClick={event => event.stopPropagation()}
      {...props}
    >
      <FlexGrowRow />
    </AppBarEx>
  )
}
