import { Divider, Grid, useTheme } from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import { copyrightLinkTitle, Footer, FooterLink, FooterProps } from '@xyo-network/react-sdk'
import React from 'react'

import { useSettings } from '../../contexts/index.ts'
import { DeveloperFooterLinks } from './DeveloperFooterLinks.tsx'
import { EcosystemFooterLinks } from './EcosystemFooterLinks.tsx'
import { ResourcesFooterLinks } from './ResourcesFooterLinks.tsx'
import { SocialFooterLinks } from './SocialFooterLinks.tsx'
import { SupportFooterLinks } from './SupportFooterLinks.tsx'
import { TokenFooterLinks } from './TokenFooterLinks.tsx'

const footerLinks = [
  {
    href: 'https://xylabs.com/',
    title: copyrightLinkTitle('XY Labs, Inc.'),
  },
  {
    href: 'https://xyo.network/',
    title: 'XYO Foundation',
  },
  {
    href: 'https://xylabs.com/privacy/',
    title: 'Privacy',
  },
  {
    href: 'https://xylabs.com/terms/',
    title: 'Terms',
  },
  {
    href: 'https://xylabs.com/jobs',
    title: 'Careers',
  },
]

export const XyoFooterStyled: React.FC<FooterProps> = ({ ...props }) => {
  const theme = useTheme()
  const { darkMode } = useSettings()
  return (
    <Footer
      paddingY={4}
      container="xl"
      sx={{ backgroundColor: darkMode ? theme.palette.primary.light : theme.palette.primary.main, color: theme.palette.primary.contrastText }}
      {...props}
    >
      <Grid spacing={2} container paddingBottom={2}>
        <Grid item xs={12} md={2}>
          <SocialFooterLinks />
        </Grid>
        <Grid item xs={6} md={2}>
          <EcosystemFooterLinks />
        </Grid>
        <Grid item xs={6} md={2}>
          <TokenFooterLinks />
        </Grid>
        <Grid item xs={6} md={2}>
          <DeveloperFooterLinks />
        </Grid>
        <Grid item xs={6} md={2}>
          <ResourcesFooterLinks />
        </Grid>
        <Grid item xs={12} md={2}>
          <SupportFooterLinks />
        </Grid>
      </Grid>
      <Divider flexItem sx={{ marginY: 3 }} />
      <FlexRow flexWrap="wrap" textTransform="uppercase" {...props}>
        {footerLinks?.map((footerLink, index) => (
          <FooterLink color="inherit" noWrap key={index} paddingX={1} margin={0} {...footerLink}>
            <small>{footerLink.title}</small>
          </FooterLink>
        ))}
      </FlexRow>
    </Footer>
  )
}
