import { ExpandMoreRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Stack, Typography } from '@mui/material'
import { LinkEx } from '@xylabs/react-link'
import { Override } from '@xylabs/sdk-js'
import React, { ReactNode, useState } from 'react'

import { NavbarSectionProps } from './NavbarDropdownDataProps.ts'

interface MobileAccordionAdditionalProps extends AccordionProps, NavbarSectionProps {
  dark?: boolean
  index: number
}

interface OptionalChildren {
  children?: ReactNode
}

// this makes the requirement for children from AccordionProps go away
type MobileAccordionProps = Override<MobileAccordionAdditionalProps, OptionalChildren>
export const MobileAccordion: React.FC<MobileAccordionProps> = ({ name, index, sectionItems, ...props }) => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const keyString = index + 'card'

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion sx={{ bgcolor: 'transparent' }} expanded={expanded === keyString} onChange={handleChange(keyString)} elevation={0} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography variant="body1" textAlign="left">
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1} alignItems="stretch">
          {sectionItems?.map((item, index) => {
            return (
              <LinkEx
                style={{ justifyContent: 'start', textAlign: 'start' }}
                key={index}
                color="inherit"
                to={item.to}
                href={item.href}
                target={item.href ? '_blank' : undefined}
              >
                <Typography>{item.linkText}</Typography>
              </LinkEx>
            )
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
