import { styled, Typography } from '@mui/material'
import { BasePage, BasePageProps, RedirectWithQuery } from '@xylabs/react-common'
import { CookieConsent } from '@xylabs/react-cookie-consent'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { ScrollToTop } from '@xylabs/react-scroll-to-top'
import { isLocalhost } from '@xylabs/react-shared'
import { forget } from '@xylabs/sdk-js'
import React, { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useSettings } from '../contexts/index.ts'
import { XyoAppBar } from '../Header/index.tsx'
import { useXyoUserEvents } from '../hooks/index.ts'
import { Newsletter } from './Newsletter.tsx'
import { XyoFooterStyled } from './XyoFooter/index.ts'

interface XyoBasePageProps extends BasePageProps {
  bottomBanner?: boolean
  devOnly?: string | boolean
  devOnlyTo?: string | boolean
  extraAppBarSpace?: boolean
  localAsDev?: boolean
  name?: string
}

// eslint-disable-next-line complexity
export const XyoBasePage: React.FC<PropsWithChildren<XyoBasePageProps>> = ({
  devOnly = false,
  devOnlyTo = false,
  localAsDev = false,
  children,
  extraAppBarSpace = true,
  name,
  title,
  bottomBanner = true,
  ...props
}) => {
  const { developerMode } = useSettings()
  const userEvents = useXyoUserEvents()
  const location = useLocation()
  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)
  forget(userEvents.viewContent({ name: name ?? title ?? 'XyoBasePage', path: location.pathname }))

  return (
    <BasePage
      title={title}
      appBar={<XyoAppBar container="lg" position="fixed" style={{ left: 0, right: 0 }} />}
      appFooter={(
        <InvertibleThemeProvider>
          <FlexCol alignItems="stretch">
            <XyoFooterStyled />
          </FlexCol>
        </InvertibleThemeProvider>
      )}
      cookieConsent={<CookieConsent />}
      {...props}
    >
      <ScrollToTop />
      <Helmet>
        <title>{`XYO: ${title ?? 'Platform'}`}</title>
      </Helmet>
      {devOnly && devRequirementPassed
        ? (
            <FlexRow margin={1}>
              <Typography variant="body1">
                Important: This page is a Developer Only page. It is possible that some information may not be correct.
              </Typography>
            </FlexRow>
          )
        : null}
      {devOnly && !devRequirementPassed
        ? <RedirectWithQuery to="/" />
        : null}
      {/* This is here to provide spacing under fixed header */}
      {extraAppBarSpace
        ? <Offset />
        : null}
      {children}
      {bottomBanner
        ? <Newsletter />
        : null}
    </BasePage>
  )
}
